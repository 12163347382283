import { parse } from 'date-fns'
import { getAgeFromDate } from './dates'

export const EU_DATE_MASK = [
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
]
export const EMAIL_REGEX =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
const URL_REGEX =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const isValidDate = (value) =>
    !Number.isNaN(parse(value, 'dd/MM/yyyy', new Date()))
export const isValidAge = (dateString) => {
    const date = parse(dateString, 'dd/MM/yyyy', new Date())
    const age = getAgeFromDate(date)
    return age >= 0 && age <= 110
}

export const isLessThan = (max) => (value) => value < max
export const isValidUrl = (value) => URL_REGEX.test(value)
export const isGreaterThan = (max) => (value) => value < max
export const isValidEmail = (value) => EMAIL_REGEX.test(value)
export const isValidPostalCode = (value) => value.length === 4
export const isValidName = (value) => !/[^a-z\s]/i.test(value)
export const isLessOrEqualThan = (max) => (value) => value <= max
export const isGreaterOrEqualThan = (max) => (value) => value >= max
export const isValidBirthDate = (value) =>
    isValidDate(value) && isValidAge(value)
export const isNumber = (value) =>
    typeof value === 'number' && !Number.isNaN(value)

export const isRequired = (value) =>
    typeof value !== 'undefined' &&
    value !== null &&
    value !== '' &&
    value.length !== 0

export const isValidPhone = (value) => {
    if (!isRequired(value)) {
        return false
    }
    const numbers = value.split('')
    const onlyHasNumbers = numbers.every(
        (number) => !Number.isNaN(parseInt(number, 10))
    )
    if (!onlyHasNumbers || numbers.length < 9) {
        return false
    }
    return true
}
