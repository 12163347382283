import roundTo from 'round-to'
import * as constants from '@connections/constants'

const { CURRENCY_ROUNDING } = constants

/** --- formatNezasaPrice() ------------------------------------------------------------- */
/** -i- Formats price in format 9999.99 to "€9999,99".
 */
export const formatNezasaPrice = (price) => `€${price}`.replace('.', ',')

export function formatPrice(price, isInteger = false, hideCents = true) {
    let finalPrice = price

    if (!isInteger) {
        finalPrice = roundTo(roundTo(price, 2) * CURRENCY_ROUNDING, 2)
    }

    const valueStringArray = finalPrice.toString().split('').reverse()
    const primarySeparator = ','
    const secondarySeparator = '.'
    let formattedCurrency = valueStringArray.reduce(
        (previousValueString, currentChar, index) => {
            if (index === 1) {
                if (valueStringArray.length <= 2) {
                    return `0${primarySeparator}${currentChar}${previousValueString}`
                }

                return primarySeparator + currentChar + previousValueString
            }
            if (index > 2 && (index - 2) % 3 === 0) {
                return currentChar + secondarySeparator + previousValueString
            }

            return currentChar + previousValueString
        },
        ''
    )
    const [euros, cents] = formattedCurrency.split(',')

    if (cents === '00' && hideCents) {
        formattedCurrency = euros
    }

    return `€${formattedCurrency}`
}

export function formatPriceWithoutSymbol(price, isInteger, hideCents) {
    const priceWithSymbol = formatPrice(price, isInteger, hideCents)

    return priceWithSymbol.replace('€', '').trim()
}
